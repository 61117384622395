body {
    background-color: #f3f3f3;
    padding-top: 70px;
  }
  
  
.img-thumbnail {
  background-color: transparent;
  border: none;
}

.navbar-dark {
  background: #2e2f31;
}

.container {
  padding: 5px;
}

.navbar-dark .navbar-nav li a{
  color: #f7f7f7;
  font-size: 16px; 
}

.navbar-dark .navbar-nav .dropdown-item {
  color:  #2e2f31;
  font-size: 14px; 
}

.navbar-dark .navbar-nav li a:hover {
  background:transparent;
}

.jumbotron {
  color : lightgray;
  background-color: #6d6d6d;
}

.card-img{
	height: auto;
  width: 50%
}

.is-cart{
  border-color: rgb(0, 128, 64); 
  border-width: 2px;
  background-color: lightgreen;
}

.form-signin {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin: auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 0.75rem;
}

.cart-input{
  max-width: 25%;
}


label {
  margin-bottom: .25rem;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.row{
  margin: auto;
}

.btn-block {
  margin-top: 2rem;
  margin-left: 0.25rem;
}